import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from './common'

// handle the public routes
const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => !getToken() ? <Component {...props} /> : <Redirect to={{ pathname: '/app' }} />}
    />
  )
}

export default PublicRoute;
