const API = {
    CHANGE_PASSWORD: '/api/ganti-password',
    LOGIN: '/api/pengguna/login',
    LOCATION: '/api/alamat',
    PROFILE: '/api/profil',
    PROGRAM_LIST: '/api/program',
    PROGRAM_DETAIL: '/api/program/:slug',
    PROGRAM_QUESTION: '/api/program/pertanyaan/:slug',
    PROGRAM_TERDAFTAR: '/api/program/program/terdaftar',
    PROGRAM_INVOICE: '/api/program/pembayaran',
    PROGRAM_MOU_INVOICE: '/api/program/invoice-mou/:slug',
    PROGRAM_UPLOAD_NASKAH: '/api/program/upload/naskah',
    REGISTER: '/api/pengguna/daftar',
}

const URL = {
    // auth url
    WELCOMEPAGE: '/',
    LOGIN: '/auth/login',
    REGISTER: '/auth/register',
    FORGOT_PASSWORD: '/auth/forgot-password',

    PROFILE: '/app/profile',
    PROFILE_CHANGE_PASSWORD: '/app/profile/change-password',
    PROGRAM: '/app/program',
    PROGRAM_DETAIL: '/app/program/:slug/detail',
    PROGRAM_FORM: '/app/program/:slug/form',
    PROGRAM_REGISTERED: '/app/program/registered',
    PROGRAM_INVOICE: '/app/program/:slug/invoice',
    PROGRAM_MOU_INVOICE: '/app/program/:slug/mou-invoice',
    PROGRAM_UPLOAD_NASKAH: '/app/program/:slug/upload-naskah',
    PROGRAM_UPSELLING: '/app/program/upselling',
    PROGRAM_UPSELLING_FORM: '/app/program/upselling/:slug/form',
}

export {
    API,
    URL,
}