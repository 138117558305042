import React from "react"
import Logo from "../../assets/images/gmb-logo.png"
import { Navbar, Nav } from "react-bootstrap"
import { URL } from "../../constants/Url"
import { NavLink as RouterLink, Route, Switch, Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import PrivateRoute from "../../components/router/privateRoute"
import { removeToken } from '../../components/router/common'

const ProgramList = React.lazy(() => import('../program'))
const ProgramDetail = React.lazy(() => import('../program/Detail'))
const ProgramForm = React.lazy(() => import('../program/Form'))
const ProgramRegistered = React.lazy(() => import('../program/List'))
const ProgramMouInvoice = React.lazy(() => import('../program/MouInvoice'))
const ProgramInvoiceForm = React.lazy(() => import('../program/InvoiceForm'))
const ProgramUploadForm = React.lazy(() => import('../program/UploadNaskahForm'))
const ProgramUpselling = React.lazy(() => import('../program/Upselling'))
const ProgramUpsellingForm = React.lazy(() => import('../program/UpsellingForm'))
const Profile = React.lazy(() => import('../profile'))
const ProfileChangePassword = React.lazy(() => import('../profile/ChangePassword'))

class MainContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            isCollapse: false
        }
    }

    click_linkLogout = () => {
        const { props } = this

        removeToken()
        props.history.replace(URL.LOGIN)
    }

    click_toggleButton = () => {
        const { state } = this

        this.setState({
            isCollapse: !state.isCollapse
        })
    }

    click_toggleButtonClose = (nextValue) => {
        this.setState({
            isCollapse: nextValue
        })
    }

    render() {
        const { state } = this

        return (
            <div className="wrapper">
                <Navbar bg="red" expand="lg">
                    <RouterLink to={URL.PROGRAM} onClick={() => this.click_toggleButtonClose(false)}>
                        <img src={Logo} alt="gmb logo"></img>
                    </RouterLink>
                    <Navbar.Toggle 
                        aria-controls="basic-navbar-nav"
                        onClick={() => this.click_toggleButton()} />
                    <Navbar className={state.isCollapse ? `custom-collapse show` : `custom-collapse not-show`} id="basic-navbar-nav">
                        <Nav>
                            <RouterLink to={URL.PROGRAM} onClick={() => this.click_toggleButtonClose(false)}>Dashboard</RouterLink>
                            <RouterLink to={URL.PROFILE} onClick={() => this.click_toggleButtonClose(false)}>Profil</RouterLink>
                            <RouterLink to={URL.PROGRAM_REGISTERED} onClick={() => this.click_toggleButtonClose(false)}>Program Terdaftar</RouterLink>
                            <RouterLink to={URL.PROGRAM_UPSELLING} onClick={() => this.click_toggleButtonClose(false)}>Program Upselling</RouterLink>
                            <RouterLink to={URL.PROFILE_CHANGE_PASSWORD} onClick={() => this.click_toggleButtonClose(false)}>Ganti Password</RouterLink>
                            <Nav.Link onClick={() => this.click_linkLogout()}>Logout</Nav.Link>
                        </Nav>
                    </Navbar>
                </Navbar>

                <section className="main-content">
                    <React.Suspense
                        fallback={<p>
                            <FontAwesomeIcon icon={faSpinner} spin /> 
                            <span>Loading...</span>
                        </p>}>
                        <Switch>
                            <Route path="/app/" render={() => <Redirect to={URL.PROGRAM} />} exact />
                            <PrivateRoute path={URL.PROGRAM} component={ProgramList} exact />
                            <PrivateRoute path={URL.PROGRAM_DETAIL} component={ProgramDetail} exact />
                            <PrivateRoute path={URL.PROGRAM_FORM} component={ProgramForm} exact />
                            <PrivateRoute path={URL.PROGRAM_REGISTERED} component={ProgramRegistered} exact />
                            <PrivateRoute path={URL.PROGRAM_INVOICE} component={ProgramInvoiceForm} exact />
                            <PrivateRoute path={URL.PROGRAM_MOU_INVOICE} component={ProgramMouInvoice} exact />
                            <PrivateRoute path={URL.PROGRAM_UPLOAD_NASKAH} component={ProgramUploadForm} exact />
                            <PrivateRoute path={URL.PROGRAM_UPSELLING} component={ProgramUpselling} exact />
                            <PrivateRoute path={URL.PROGRAM_UPSELLING_FORM} component={ProgramUpsellingForm} exact />
                            <PrivateRoute path={URL.PROFILE} component={Profile} exact />
                            <PrivateRoute path={URL.PROFILE_CHANGE_PASSWORD} component={ProfileChangePassword} exact />
                        </Switch>
                    </React.Suspense>
                </section>
            </div>
        )
    }
}

export default MainContainer