import * as Yup from 'yup'

const initFormLogin = {
    email: '',
    password: '',
}

const initFomForgotPassword = {
    email: '',
}

const initFormRegister = {
    nama: '',
    tgl_lahir: '',
    jenis_kelamin: '',
    pendidikan_terakhir: '',
    email: '',
    password: '',
    nohp: '',
    sosial_media: '',
    provinsi_id: '',
    kabupaten_id: '',
    status: '',
    instansi: '',
    alamat: '',
}

const valid_formSignIn = Yup.object().shape({
    email: Yup.string()
            .email('invalid email address')
            .required(),
    password: Yup.string()
            .required('Required') 
            .matches(/[\W\S_]/, 'Only Numbers and letters.')
})

const valid_formForgotPassword = Yup.object().shape({
    email: Yup.string()
            .email('invalid email address')
            .required(),
})

const valid_formSignUp = Yup.object().shape({
    nama: Yup.string().required('This field is required'),
    tgl_lahir: Yup.date().required('This field is required'),
    jenis_kelamin: Yup.string().required('This field is required'),
    pendidikan_terakhir: Yup.string().required('This field is required'),
    email: Yup.string()
            .email('invalid email address')
            .required(),
    password: Yup.string()
            .required('Required') 
            .matches(/[\W\S_]/, 'Only Numbers and letters.'),
    nohp: Yup.number().required('This field is required'),
    sosial_media: Yup.string().required('This field is required'),
    provinsi_id: Yup.number().required('This field is required'),
    kabupaten_id: Yup.number().required('This field is required'),
    status: Yup.string().required('This field is required'),
    instansi: Yup.string().required('This field is required'),
})

export {
    initFormLogin,
    initFomForgotPassword,
    initFormRegister,
    valid_formSignIn,
    valid_formSignUp,
    valid_formForgotPassword,
}