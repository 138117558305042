import React from "react"
import "../assets/css/main.scss"
import { URL } from "../constants/Url"
import logo from "../assets/images/gmb-logo.png"
import { Button, Container } from 'react-bootstrap'

class WelcomePage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {  }
    }
    render() {
        return (
            <div className="welcome-page wrapper">
                <header className="header">
                    <img src={logo} alt="GMB Logo" />
                </header>
                <Container>
                    <div className="welcome-page-body">
                        <p className="welcome-page-title-label">Selamat datang di</p>
                        <h1 className="welcome-page-title">Pusat Pengembangan Literasi Nasional</h1>
                        <p className="welcome-page-description">Platform pendaftaran program - program gerakan membaca indonesia khusus untuk mempermudah manajemen program</p>
                    </div>
                    <div className="welcome-page-title-footer">
                        <Button onClick={() => this.props.history.push(URL.REGISTER)}>DAFTAR</Button>
                        <p>Sudah memiliki akun? <Button variant="link" onClick={() => this.props.history.push(URL.LOGIN)}>Sign In</Button></p>
                    </div>
                </Container>
            </div>
        )
    }
}

export default WelcomePage