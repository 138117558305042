import { apiClient } from '../../helper'
import { API } from '../../constants/Url'

const post_loginUser = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.LOGIN, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post login has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_registerUser = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.REGISTER, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post register has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_changePassword = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.CHANGE_PASSWORD, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

export {
    post_loginUser,
    post_registerUser,
    post_changePassword,
}
