import React from 'react'
import PropTypes from 'prop-types'
import { Alert, Fade } from 'react-bootstrap'

const CustomInfoMessage = props => {

    return props.show && (
        <Fade in={props.show} >
            <Alert 
                variant={props.variant} 
                className={`${props.variant === 'danger' && 'custom-alert'} text-left `}>
                    <Alert.Heading>{props.variant === 'danger' ? 'Error' : props.variant.toUpperCase()}</Alert.Heading>
                    <p>{props.body}</p>
            </Alert>
        </Fade>
    )
}

CustomInfoMessage.defaultProps = {
    show: false,
    variant: 'info',
    header: 'example header',
    body: 'nothing text body to display'
}

CustomInfoMessage.propTypes = {
    show: PropTypes.bool,
    variant: PropTypes.oneOf(['danger', 'info', 'warning', 'success']),
    header: PropTypes.string,
    body: PropTypes.string,
}

export default CustomInfoMessage