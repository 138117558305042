import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from './common'
import { URL } from '../../constants/Url'

// handle the private routes
const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
        <Route
        {...rest}
        render={(props) => getToken() ? <Component {...props} /> : <Redirect to={{ pathname: URL.LOGIN, state: { from: props.location } }} />}
        />
    )
}
  
export default PrivateRoute;
