import React from 'react'
import "../../assets/css/main.scss"
import { Alert, Button, Form } from 'react-bootstrap'
import { Formik } from 'formik'
import { get_listLocation } from '../../actions/master'
import { initFormRegister, valid_formSignUp } from './common/const'
import { serializeForm } from '../../helper'
import { URL } from '../../constants/Url'
import { post_registerUser } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"

class Register extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            formRegister: {
                ...initFormRegister,
                isLoading: false
            },
            locationData: {
                province: [],
                kabupaten: [],
                selectedProvince: null,
                selectedKabupaten: null,
                isLoading: false,
            },
            info: {
                isShow: null,
                infoVariant: 'danger',
                infoBody: null,
            },
        }
    }

    componentDidMount() {
        this.api_getListLocation()
    }

    api_getListLocation = () => {
        const { state } = this
        const locationData = {...state.locationData}

        locationData.isLoading = true
        this.setState({
            locationData
        }, () => {
            get_listLocation(null, 
                resp => {
                    const { data } = resp

                    locationData.isLoading = false
                    locationData.province = data.data
                    this.setState({
                        locationData
                    })
                },

                err => {
                    locationData.isLoading = false
                    this.setState({
                        locationData
                    })
                }
            )
        })
    }
    
    click_buttonSubmit = (values, formikProps) => {
        const { props, state } = this
        const formData = serializeForm(initFormRegister, values, 'form-data')
        const formRegister = {...state.formRegister}

        formRegister.isLoading = true
        this.setState({
            formRegister
        }, () => {
            post_registerUser(formData,
                resp => {
                    const { data } = resp
                    formRegister.isLoading = false
                    this.setState({
                        formRegister
                    })

                    cksClient().set('_authToken', data.data.access_token, {
                        path: '/',
                        sameSite: 'Lax'
                    })
                    return props.history.push(URL.PROGRAM)
                },

                err => {
                    const data = err.data

                    if (data) {
                        this.set_toggleInfo(true, 'danger', data.errors.email[0] ?? 'Please check your data again')
                        formikProps.setFieldError('email', data.errors.email[0] ?? 'Please check your data again')
                    }

                    formRegister.isLoading = false
                    this.setState({
                        formRegister
                    })
                }
            )
        })

    }

    selectedProvince = (value, formikProps) => {
        const { state } = this
        const locationData = {...state.locationData}
        const formRegister = {...formikProps}

        locationData.isLoading = true
        this.setState({
            locationData
        }, () => {
            let selected = locationData.province.filter(x => x.id_provinsi === Number(value))[0]

            locationData.isLoading = false
            locationData.selectedProvince = value
            locationData.kabupaten = selected.kabupaten
            formRegister.provinsi_id = value
            this.setState({
                locationData,
                formRegister,
            })
        })
    }

    selectedKabupaten = (value, formikProps) => {
        const formRegister = {...formikProps}

        formRegister.kabupaten_id = value
        this.setState({
            formRegister
        })
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    render() {
        const { state } = this

        return (
            <>
                <div className="registration-page wrapper">
                    <header>
                        <h1>Registration GMB</h1>
                    </header>
                    <div className="registration-form container my-3">
                        <div className="custom-card p-3">
                            <Formik
                                initialValues={state.formRegister}
                                onSubmit={(values, formikProps) => this.click_buttonSubmit(values, formikProps)}
                                validationSchema={valid_formSignUp}
                                validateOnBlur={false}
                                validateOnChange={true}
                                >
                                {formikProps => (
                                    <Form>
                                        {
                                            state.info.isShow ?
                                            <CustomInfoMessage 
                                                show={state.info.isShow}
                                                variant={state.info.infoVariant}
                                                header={state.info.infoVariant}
                                                body={state.info.infoBody}
                                            />
                                            :
                                            <Alert variant="danger">
                                                PERHATIAN!!
                                                <br/>
                                                Dimohon untuk mengisi data dengan valid.
                                            </Alert>
                                        }
                                        <Form.Group>
                                            <Form.Label>Nama Lengkap (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Nama"
                                                name="nama"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.nama}
                                                error={formikProps.errors.nama}
                                                isInvalid={formikProps.errors.nama} />
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Tanggal Lahir (*)</Form.Label>
                                            <Form.Control 
                                                type="date" 
                                                placeholder="Tanggal Lahir"
                                                name="tgl_lahir"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.tgl_lahir}
                                                error={formikProps.errors.tgl_lahir}
                                                isInvalid={formikProps.errors.tgl_lahir} />
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Jenis Kelamin (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="jenis_kelamin"
                                                onChange={(e) => formikProps.setFieldValue('jenis_kelamin', e.target.value)}
                                                value={formikProps.values.jenis_kelamin}
                                                error={formikProps.errors.jenis_kelamin}
                                                isInvalid={formikProps.errors.jenis_kelamin} >
                                                    <option>Jenis Kelamin</option>
                                                    <option value="L">Laki - Laki</option>
                                                    <option value="P">Perempuan</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Pendidikan Terakhir (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="pendidikan_terakhir"
                                                onChange={(e) => formikProps.setFieldValue('pendidikan_terakhir', e.target.value)}
                                                value={formikProps.values.pendidikan_terakhir}
                                                error={formikProps.errors.pendidikan_terakhir}
                                                isInvalid={formikProps.errors.pendidikan_terakhir}>
                                                    <option>Pendidikan Terakhir</option>
                                                    <option value="sd">Sekolah Dasar (SD)</option>
                                                    <option value="smp">Sekolah Menengah Pertama (SMP)</option>
                                                    <option value="sma">Sekolah Menengah Atas (SMA)</option>
                                                    <option value="diploma3">Diploma-3 (D3)</option>
                                                    <option value="sarjana">Sarjana (S1)</option>
                                                    <option value="magister">Magister (S2)</option>
                                                    <option value="doktor">Doktor (S3)</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Email (*)</Form.Label>
                                            <Form.Control 
                                                type="email" 
                                                placeholder="Email"
                                                name="email"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.email}
                                                error={formikProps.errors.email}
                                                isInvalid={formikProps.errors.email} />
                                        </Form.Group>
                                        
                                        <Form.Group>
                                            <Form.Label>Password (*)</Form.Label>
                                            <Form.Control 
                                                type="password" 
                                                placeholder="Password"
                                                name="password"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.password}
                                                error={formikProps.errors.password}
                                                isInvalid={formikProps.errors.password} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Nomor Hp (*)</Form.Label>
                                            <Form.Control 
                                                type="number" 
                                                placeholder="Nomor Hp"
                                                name="nohp"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.nohp}
                                                error={formikProps.errors.nohp}
                                                isInvalid={formikProps.errors.nohp} />
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Link Sosial Media (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Link Sosial Media"
                                                name="sosial_media"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.sosial_media}
                                                error={formikProps.errors.sosial_media}
                                                isInvalid={formikProps.errors.sosial_media} />
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Provinsi (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="provinsi_id"
                                                error={formikProps.errors.provinsi_id}
                                                onChange={(e) => {
                                                        this.selectedProvince(e.target.value, formikProps.values)
                                                        return formikProps.setFieldValue('provinsi_id', e.target.value)
                                                    }
                                                }
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.provinsi_id}
                                                disabled={state.locationData.isLoading}>
                                                    <option value="" key="">{state.locationData.isLoading ? `Loading...` : `Pilih Provinsi` }</option>
                                                    {!state.locationData.isLoading && state.locationData.province.map((value,key) => (
                                                        <option value={value.id_provinsi} key={key}>{value.nama_provinsi}</option>
                                                    ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Kabupaten (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="kabupaten_id"
                                                error={formikProps.errors.kabupaten_id}
                                                onChange={(e) => {
                                                        this.selectedKabupaten(e.target.value, formikProps.values)
                                                        return formikProps.setFieldValue('kabupaten_id', e.target.value)
                                                    }
                                                }
                                                onBlur={formikProps.handleBlur}
                                                value={formikProps.values.kabupaten_id}
                                                disabled={state.locationData.isLoading}>
                                                    <option value="" key="">{state.locationData.isLoading ? `Loading...` : `Pilih Kabupaten` }</option>
                                                    {!state.locationData.isLoading && state.locationData.kabupaten.map((value,key) => (
                                                        <option value={value.id_kota} key={key}>{value.nama_kota}</option>
                                                    ))}
                                            </Form.Control>
                                        </Form.Group>

                                        <Form.Group>
                                            <Form.Label>Status (*)</Form.Label>
                                            <Form.Control 
                                                as="select"
                                                name="status"
                                                onChange={(e) => formikProps.setFieldValue('status', e.target.value)}
                                                value={formikProps.values.status}
                                                error={formikProps.errors.status}
                                                isInvalid={formikProps.errors.status} >
                                                    <option>Pilih Status</option>
                                                    <option value="Pelajar">Pelajar</option>
                                                    <option value="Guru">Guru</option>
                                                    <option value="Lainnya">Lainnya</option>
                                            </Form.Control>
                                        </Form.Group>
            
                                        <Form.Group>
                                            <Form.Label>Nama Instansi (*)</Form.Label>
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Instansi"
                                                name="instansi"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.instansi}
                                                error={formikProps.errors.instansi}
                                                isInvalid={formikProps.errors.instansi} />
                                        </Form.Group>

                                        <Form.Group controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Alamat</Form.Label>
                                            <Form.Control 
                                                as="textarea" 
                                                rows={3}
                                                placeholder="alamat"
                                                name="alamat"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.alamat}
                                                error={formikProps.errors.alamat}
                                                isInvalid={formikProps.errors.alamat} />
                                        </Form.Group>

                                        <Form.Group className="form-footer">
                                            <Button 
                                                type="submit"
                                                onClick={formikProps.handleSubmit}
                                                disabled={state.formRegister.isLoading}>
                                                    {state.formRegister.isLoading ? <FontAwesomeIcon icon={faSpinner} spin />  :'DAFTAR'}
                                            </Button>
                                        </Form.Group>
            
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default Register