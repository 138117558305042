import { cksClient } from '../../helper'

// return the token from the session storage
export const getToken = () => {
    return cksClient().get('_authToken') || null;
}

// return remove token
export const removeToken = () => {
    cksClient().remove('_authToken', {
        path: '/',
        sameSite: 'Lax'
    })
}
